import {
  generateLocationQueryString,
  generateUrlParamsArray,
  getLocationQueryStringParam,
} from 'utils/browser';

import { ALGOLIA_URL_PARAMS, DefaultSearchState } from './constants';
import {
  IAlgoliaFilterIdUrlParam,
  IAlgoliaRefinementList,
  IAlgoliaSearchState,
  IChosenFilterItem,
} from './models';

export const removeItemInRefinementList = (
  refinementIds: string[] | undefined,
  refinementId: string
): string[] | null => {
  if (!refinementIds?.length) {
    return null;
  }

  const data = refinementIds.filter((item: string) => item !== refinementId);

  if (!data.length) {
    return null;
  }

  return data;
};

export const isActiveRefinement = (
  refinementList: IAlgoliaRefinementList,
  refinementId: string,
  sectionId: string
): boolean => Boolean(refinementList?.[sectionId]?.includes(refinementId));

export const encodeFilterIdParam = ({ sectionId, itemId }: IAlgoliaFilterIdUrlParam): string =>
  `${sectionId}-${itemId}`;

export const decodeFilterIdParam = (param: string): IAlgoliaFilterIdUrlParam => {
  const rawData = param.split('-');

  return { sectionId: rawData[0], itemId: rawData[1] };
};

export const getUrlToSet = (
  chosenFilterItems: IChosenFilterItem[],
  pathname?: string
): string | null => {
  const chosenFilterIds = chosenFilterItems.map((item: IChosenFilterItem) =>
    encodeFilterIdParam({ sectionId: item.sectionId, itemId: String(item.itemId) })
  );

  if (!chosenFilterIds.length) {
    return null;
  }

  return generateLocationQueryString(
    ALGOLIA_URL_PARAMS.filterSection,
    generateUrlParamsArray(chosenFilterIds),
    pathname
  );
};

export const getSavedSelectionDataToSet = (): IAlgoliaSearchState | null => {
  const chosenSavedFilterIds = getLocationQueryStringParam(
    ALGOLIA_URL_PARAMS.filterSection,
    'array'
  );

  if (!chosenSavedFilterIds.length || !chosenSavedFilterIds[0].length) {
    return null;
  }

  const dataToSet = chosenSavedFilterIds[0].map((item: string) => decodeFilterIdParam(item));

  const selectionDataToSet = { ...DefaultSearchState, refinementList: {} };
  dataToSet.forEach((item: IAlgoliaFilterIdUrlParam) => {
    if (!selectionDataToSet.refinementList[item.sectionId]) {
      selectionDataToSet.refinementList[item.sectionId] = [];
    }

    selectionDataToSet.refinementList[item.sectionId].push(item.itemId);
  });

  return selectionDataToSet;
};
