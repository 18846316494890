import React, {
  ChangeEvent,
  FC,
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

import Container from 'layout/Container';

import IconCustom from 'common/IconCustom';

import './CustomSearchBox.scss';
import './ShadowCustomSearchBox.scss';
import { IPropsCustomSearchBox } from './models';

export const CustomSearchBox: FC<IPropsCustomSearchBox> = ({
  title,
  searchIconFormAriaLabel,
  refine,
  handleSetSearchUrlParam,
  resetIconFormAriaLabel,
  isResultExist,
  savedQuery,
  isSearchStalled,
  handleLoadingStatus,
  searchTitle,
}): ReactElement | null => {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setTimeout(() => handleLoadingStatus(isSearchStalled), 200);
  }, [isSearchStalled]);

  useEffect(() => {
    setInputValue(savedQuery || '');
  }, [savedQuery]);

  const handleValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value),
    []
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const cleanValue = inputValue.trim();
      setInputValue(cleanValue);
      refine(cleanValue);
      handleSetSearchUrlParam(cleanValue);
    },
    [inputValue]
  );

  const handleReset = useCallback(() => {
    refine('');
    setInputValue('');
    handleSetSearchUrlParam('');
  }, []);

  return (
    <div data-test="CustomSearchBox" className="custom-search-box">
      <Container fluid>
        <div className="custom-search-box__top-part">
          {!isResultExist ? <h2 className="custom-search-box__text">{title}</h2> : null}
          <form noValidate className="custom-search-box__form" onSubmit={handleSubmit}>
            <input
              title={searchTitle || 'search'}
              aria-label={searchTitle || 'search'}
              type="search"
              value={inputValue}
              onChange={handleValueChange}
              className="form__input"
            />

            <button
              aria-label={searchIconFormAriaLabel}
              type="submit"
              className="form__search-button"
            >
              <IconCustom icon="search" className="form__search-button-icon" />
            </button>
            {inputValue ? (
              <button
                className="form__reset-button"
                type="button"
                onClick={handleReset}
                aria-label={resetIconFormAriaLabel}
              >
                <IconCustom icon="cross" className="form__search-button-icon" />
              </button>
            ) : null}
          </form>
        </div>
      </Container>
    </div>
  );
};

const ConnectedCustomSearchBox = connectSearchBox(CustomSearchBox);

export default ConnectedCustomSearchBox;
