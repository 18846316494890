const DEFAULT_NODE_FIELDS = `
  id
  link
  lang
  seo {
    seoMetaTitle
    seoMetaKeywords
    seoMetaDescription
  }
`;

const ALGOLIA_SEARCH_ENTITY_TYPES = {
  article: 'article',
  product: 'product',
  symptoms: 'symptoms',
  whyStrepsils: 'whyStrepsils',
  others: 'others',
};

const ALGOLIA_SEARCH_FIELDS = {
  mainSearchable: 'contentToSearch',
  entity: 'searchEntity',
};

module.exports = { DEFAULT_NODE_FIELDS, ALGOLIA_SEARCH_ENTITY_TYPES, ALGOLIA_SEARCH_FIELDS };
