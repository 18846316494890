import React, { FC, ReactElement } from 'react';

import { isNotEmptyArray } from 'utils/array';
import Container from 'layout/Container';

import FeaturedArticles from 'components/FeaturedArticles';
import FeaturedProducts from 'components/FeaturedProducts';
import UsefulLinks from 'components/UsefulLinks';

import './SearchNoResults.scss';
import { IPropsSearchNoResults } from './models';

const SearchNoResults: FC<IPropsSearchNoResults> = ({
  defaultCompositions,
  featuredArticlesList,
  featuredArticlesByLink,
  featuredArticlesByTag,
  dir,
  ariaLabelPrev,
  ariaLabelNext,
  featuredProducts,
  featuredProductsByLink,
  featuredProductsByTag,
  activeColor,
  usefulLinks,
}): ReactElement | null => (
  <div data-test="SearchNoResults" className="search-no-results">
    <Container fluid>
      <UsefulLinks usefulLinks={usefulLinks} />
    </Container>
    {isNotEmptyArray(featuredArticlesList) ? (
      <FeaturedArticles
        featuredArticlesList={featuredArticlesList}
        featuredArticlesByLink={featuredArticlesByLink.nodes}
        featuredArticlesByTag={featuredArticlesByTag.nodes}
        dir={defaultCompositions.siteSettings.dir[0]}
        ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
        ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
      />
    ) : null}
    <FeaturedProducts
      featuredProducts={featuredProducts}
      featuredProductsByLink={featuredProductsByLink}
      featuredProductsByTag={featuredProductsByTag}
      productsOrder={featuredProducts?.[0]?.properties?.selectedProductsIds || []}
      dir={dir}
      ariaLabelPrev={ariaLabelPrev}
      ariaLabelNext={ariaLabelNext}
      activeColor={activeColor}
    />
  </div>
);

export default SearchNoResults;
