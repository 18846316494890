import React, { FC } from 'react';

import IconCustom from 'common/IconCustom';

import { IPropsUsefulLinks } from './models';
import './UsefulLinks.scss';
import './ShadowUsefulLinks.scss';

const UsefulLinks: FC<IPropsUsefulLinks> = ({ usefulLinks }) => {
  const {
    properties: { title, link },
  } = usefulLinks[0];

  return (
    <div data-test="UsefulLinks" className="useful-links__wrapper">
      {title ? <h3 className="useful-links__title">{title}</h3> : null}
      <ul>
        {link?.map(({ name, url }) => (
          <li key={name} className="useful-links__li">
            <a href={url} className="useful-links__link">
              <span className="useful-links__name">{name}</span>
              <IconCustom icon="arrow3-up" className="useful-links__icon" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsefulLinks;
